@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap");
/* body {
  color: #eee;
  background-color: #444;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 18px;
}
html p {
  line-height: 1.5;
  hyphens: auto;
}
html a {
  color: #eee;
}
html button {
  font-family: Roboto Mono, monospace;
  font-size: 1.2rem;
  border: 2px #eee solid;
  cursor: pointer;
  color: #eee;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
}
html button:hover {
  color: #444;
  background-color: #eee;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.App {
  margin: auto;
  max-width: 640px;
  padding: 0 0.5rem;
}

.button--back {
  border: 2px #eee solid;
  color: #eee;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.button--back:hover {
  color: #444;
  background-color: #eee;
}
.button--back:active {
  color: #444;
  background-color: #eee;
}
.button--start {
  border: 2px mediumspringgreen solid;
  color: mediumspringgreen;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.button--start:hover {
  color: #eee;
  background-color: mediumspringgreen;
}
.button--start:active {
  color: #eee;
  background-color: mediumspringgreen;
}
.button--menu {
  color: mediumspringgreen;
  padding: 1rem;
  margin: 1rem;
}
.button--menu:hover {
  color: #eee;
  background-color: mediumspringgreen;
}
.button--menu--test {
  border: 2px mediumspringgreen solid;
  color: mediumspringgreen;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
  width: 100%;
}
.button--menu--test:hover {
  color: #eee;
  background-color: mediumspringgreen;
}
.button--menu--test:active {
  color: #eee;
  background-color: mediumspringgreen;
}
.button--menu--other {
  border: 2px #eee solid;
  color: #eee;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
  width: 100%;
}
.button--menu--other:hover {
  color: #444;
  background-color: #eee;
}
.button--menu--other:active {
  color: #444;
  background-color: #eee;
}
.button--reset {
  border: 2px indianred solid;
  color: indianred;
  background-color: #444;
  transition: color 400ms, background-color 400ms;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
}
.button--reset:hover {
  color: #eee;
  background-color: indianred;
}
.button--reset:active {
  color: #eee;
  background-color: indianred;
}

.agreement {
  padding: 1rem;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.menu__app-title {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
}
.menu__tests {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0;
}
.menu__others {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.explain-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.explain-page__content {
  margin-top: 3rem;
  width: 300px;
}

.end-notice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto;
  width: 300px;
}

.results__coffee-data__input {
  border: 2px #eee solid;
  height: 2rem;
  width: 3rem;
  padding: 0rem 0.5rem;
  background-color: #444;
  color: #eee;
  font-size: 1.5rem;
  font-family: Roboto Mono, monospace;
}
.results__test-data__container {
  border: 2px #eee solid;
  margin: 1rem 0;
  padding: 0.5rem;
  max-width: 280px;
}
.results__test-data__container__test-name {
  margin: 0;
  font-weight: bolder;
}
.results__test-data__container__results {
  font-family: Roboto Mono, monospace;
  font-size: 1.5rem;
  margin: 0;
}
.results__test-data__send-request {
  color: mediumspringgreen;
  margin-top: 3rem;
}
.results__warning {
  color: indianred;
  margin-top: 3rem;
}

.nback__attention {
  font-size: 1.5rem;
}
.nback__current-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.nback__current-data__num {
  font-family: Roboto Mono, monospace;
  margin: 0.75rem;
  width: 2.5rem;
  text-align: center;
  font-size: 2rem;
}
.nback__current-data__num--hidden {
  background-color: #eee;
}
.nback__qa__question {
  text-align: center;
  margin: 2rem auto;
  font-size: 1.5rem;
}
.nback__qa__answers--nb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-items: center;
}
.nback__qa__answers--nb__answer {
  width: 5rem;
  padding: 0.25rem 1rem;
  font-family: Roboto Mono, monospace;
  font-size: 2rem;
  margin: 0.75rem;
}
.nback__qa__answers--mu {
  display: flex;
  justify-content: space-around;
}
.nback__qa__answers--mu__answer {
  width: 7rem;
  padding: 0.4rem 1rem;
} */

.corsiblock__instruction {
    height: 6rem;
}
.corsiblock__instruction__content {
    text-align: center;
    font-size: 1.2rem;
}
.corsiblock__blocks {
    margin: 1rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 7rem;
    grid-column-gap: 0rem;
    justify-items: center;
}
.corsiblock__blocks__container {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.corsiblock__blocks__container__block {
    width: 3.5rem;
    height: 3.5rem;
    background-color: #adadad;
    box-shadow: 3px 3px #222;
    transition: width 400ms, height 400ms, background-color 400ms;
}
.corsiblock__blocks__container__block--clickable {
    cursor: pointer;
}
.corsiblock__blocks__container__block--highlighted {
    background-color: #009900;
    width: 4.1rem;
    height: 4.1rem;
}
